module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-seed-design/gatsby-browser.tsx'),
      options: {"plugins":[],"mode":"light-only"},
    },{
      plugin: require('../node_modules/@raae/gatsby-plugin-let-it-snow/gatsby-browser.js'),
      options: {"plugins":[],"colors":["--snow-color-1","--snow-color-2"],"intensity":"light","duration":18,"season":{"start":"2001-12-01T00:00:00.000Z","end":"2001-01-04T00:00:00.000Z"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Pretendard"],"urls":["https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css"]}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
